import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";
import { Helmet } from "react-helmet";

const Seo = ({ title, description }) => {
  const { pathname } = useLocation();
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteTitle
          siteUrl
          defaultDescription
          image
        }
      }
    }
  `);
  const { siteTitle, defaultDescription, siteUrl, image } =
    data.site.siteMetadata;
  const seo = {
    title: title ? `${title} | ${siteTitle}` : siteTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
    image: `${siteUrl}${image}`,
  };
  return (
    <Helmet title={seo.title} htmlAttributes={{ lang: "ja" }}>
      <meta name="description" content={description} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@Yoshgan_Tzarino" />
      <meta name="twitter:creator" content="@Yoshgan_Tzarino" />
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
    </Helmet>
  );
};

export default Seo;
